import { default as index5orPGiYGGnMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/2percenta-z-dani/index.vue?macro=true";
import { default as _91name_93VEnQ83EMvzMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/archiv/[name].vue?macro=true";
import { default as indexQiDNRqOdo5Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/archiv/index.vue?macro=true";
import { default as _91name_93j3sveTNvanMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/clanok/[name].vue?macro=true";
import { default as index3VRWIWOdH7Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/clanok/index.vue?macro=true";
import { default as _91name_93nU9sbM9M6yMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusia/[name].vue?macro=true";
import { default as indexha9q2kyyxRMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusia/index.vue?macro=true";
import { default as duhova_45tolerancialPZ2nLDJseMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusie/duhova-tolerancia.vue?macro=true";
import { default as indexTLrJz6AVYzMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusie/index.vue?macro=true";
import { default as indexG2KYmiKOC7Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/e-mailova-poradna-linky-dovery-nezabudka/index.vue?macro=true";
import { default as indexV6dR5jz2QBMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/error404/index.vue?macro=true";
import { default as _91name_93k1EzPCnVzXMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/event-nezabudka/[name].vue?macro=true";
import { default as indexv694jcTSSsMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/event-nezabudka/index.vue?macro=true";
import { default as indexR0cGJ2SSJwMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/index.vue?macro=true";
import { default as _91name_936NALDlNtEnMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampan/[name].vue?macro=true";
import { default as indexTlGDtYDAnxMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampan/index.vue?macro=true";
import { default as dobroPyLJ8X43XPMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampane/dobro.vue?macro=true";
import { default as indexe7f1XQPQeaMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampane/index.vue?macro=true";
import { default as _91type_933gZFEScoPWMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kategoria/[name]/[type].vue?macro=true";
import { default as indexwbfqBTf44yMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kategoria/[name]/index.vue?macro=true";
import { default as _91name_93GYaX7EoDXQMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kurz/[name].vue?macro=true";
import { default as indexyD7MjeznuYMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kurz/index.vue?macro=true";
import { default as _91name_93GKqRcBmlAHMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/letak/[name].vue?macro=true";
import { default as indexqd6WU0VtbQMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/letak/index.vue?macro=true";
import { default as indexNfTbu4c33sMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/mapa-podpory/index.vue?macro=true";
import { default as clenovia_45ligyb0G6P38lMFMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/clenovia-ligy.vue?macro=true";
import { default as eticke_45kodexygjB4mlI3tkMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/eticke-kodexy.vue?macro=true";
import { default as kontakty1Mb33cadJ7Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/kontakty.vue?macro=true";
import { default as kto_45smecdbZd9pbz7Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/kto-sme.vue?macro=true";
import { default as partneri_45a_45donoril9iNedZsn3Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/partneri-a-donori.vue?macro=true";
import { default as pre_45media5vcfsHrFx6Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/pre-media.vue?macro=true";
import { default as stanoviska_45ligyzTRNtwE7IlMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/stanoviska-ligy.vue?macro=true";
import { default as vyrocne_45spravyHFzUlckmzFMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/vyrocne-spravy.vue?macro=true";
import { default as indexUHx35nGbRHMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/ocenenie-dobre-kruhy/index.vue?macro=true";
import { default as indexIPXsqCw5jmMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/ochrana-osobnych-udajov/index.vue?macro=true";
import { default as _91name_93lxu3zkjhNUMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/organizacia/[name].vue?macro=true";
import { default as indexHyPQPREWVBMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/otazky-a-odpovede/index.vue?macro=true";
import { default as _91name_932XJ08XdSFQMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/peerovia/[name].vue?macro=true";
import { default as indexDX1QMA3I5zMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/peerovia/index.vue?macro=true";
import { default as _91name_931XPNjrUVKbMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podcast/[name].vue?macro=true";
import { default as indexkUMaE5NMCFMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podcast/index.vue?macro=true";
import { default as indexlm1cADe6GlMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podporte-nas/index.vue?macro=true";
import { default as _91name_93ALR0YL2vFgMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/[name].vue?macro=true";
import { default as indexaQatcMc1ujMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/index.vue?macro=true";
import { default as slavnostny_45koncert8TExj4nprzMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/slavnostny-koncert.vue?macro=true";
import { default as indexs4b68eziREMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/potrebujem-pomoc/index.vue?macro=true";
import { default as indexuwOEPoqqUIMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/preview/index.vue?macro=true";
import { default as _91name_93NJIFZS7LlwMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/projekt/[name].vue?macro=true";
import { default as indexMP0BBLTVePMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/projekt/index.vue?macro=true";
import { default as indexAQV1klCVWyMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/publikacie/index.vue?macro=true";
import { default as indexbasMcltAMUMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/staznosti-a-spatna-vazba/index.vue?macro=true";
import { default as _91name_93j2hQlKFL2rMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/stranka/[name].vue?macro=true";
import { default as indexXpe6c82Vy9Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/stranka/index.vue?macro=true";
import { default as indexNiTZyWiuRBMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/testy/index.vue?macro=true";
import { default as _91name_93n78gD5tIk0Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/video/[name].vue?macro=true";
import { default as index98uzpRRlT5Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/video/index.vue?macro=true";
import { default as _91categorySlug_93DC0ZET9JYnMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vsetky-vysledky/[slug]/[categorySlug].vue?macro=true";
import { default as _91searchText_93O8881lo5JWMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyhladavanie/[searchText].vue?macro=true";
import { default as _91name_93BTmaHlo7HTMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzva/[name].vue?macro=true";
import { default as indexgCOvAIWbDYMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzva/index.vue?macro=true";
import { default as indexh7v17xlgCuMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzvy/index.vue?macro=true";
import { default as indexQhp10Nup7nMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/zbierka-dni-nezabudiek/index.vue?macro=true";
export default [
  {
    name: "2percenta-z-dani",
    path: "/2percenta-z-dani",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/2percenta-z-dani/index.vue").then(m => m.default || m)
  },
  {
    name: "archiv-name",
    path: "/archiv/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/archiv/[name].vue").then(m => m.default || m)
  },
  {
    name: "archiv",
    path: "/archiv",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/archiv/index.vue").then(m => m.default || m)
  },
  {
    name: "clanok-name",
    path: "/clanok/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/clanok/[name].vue").then(m => m.default || m)
  },
  {
    name: "clanok",
    path: "/clanok",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/clanok/index.vue").then(m => m.default || m)
  },
  {
    name: "diskusia-name",
    path: "/diskusia/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusia/[name].vue").then(m => m.default || m)
  },
  {
    name: "diskusia",
    path: "/diskusia",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusia/index.vue").then(m => m.default || m)
  },
  {
    name: "diskusie-duhova-tolerancia",
    path: "/diskusie/duhova-tolerancia",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusie/duhova-tolerancia.vue").then(m => m.default || m)
  },
  {
    name: "diskusie",
    path: "/diskusie",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusie/index.vue").then(m => m.default || m)
  },
  {
    name: "e-mailova-poradna-linky-dovery-nezabudka",
    path: "/e-mailova-poradna-linky-dovery-nezabudka",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/e-mailova-poradna-linky-dovery-nezabudka/index.vue").then(m => m.default || m)
  },
  {
    name: "error404",
    path: "/error404",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/error404/index.vue").then(m => m.default || m)
  },
  {
    name: "event-nezabudka-name",
    path: "/event-nezabudka/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/event-nezabudka/[name].vue").then(m => m.default || m)
  },
  {
    name: "event-nezabudka",
    path: "/event-nezabudka",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/event-nezabudka/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kampan-name",
    path: "/kampan/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampan/[name].vue").then(m => m.default || m)
  },
  {
    name: "kampan",
    path: "/kampan",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampan/index.vue").then(m => m.default || m)
  },
  {
    name: "kampane-dobro",
    path: "/kampane/dobro",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampane/dobro.vue").then(m => m.default || m)
  },
  {
    name: "kampane",
    path: "/kampane",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampane/index.vue").then(m => m.default || m)
  },
  {
    name: "kategoria-name-type",
    path: "/kategoria/:name()/:type()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kategoria/[name]/[type].vue").then(m => m.default || m)
  },
  {
    name: "kategoria-name",
    path: "/kategoria/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kategoria/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: "kurz-name",
    path: "/kurz/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kurz/[name].vue").then(m => m.default || m)
  },
  {
    name: "kurz",
    path: "/kurz",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kurz/index.vue").then(m => m.default || m)
  },
  {
    name: "letak-name",
    path: "/letak/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/letak/[name].vue").then(m => m.default || m)
  },
  {
    name: "letak",
    path: "/letak",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/letak/index.vue").then(m => m.default || m)
  },
  {
    name: "mapa-podpory",
    path: "/mapa-podpory",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/mapa-podpory/index.vue").then(m => m.default || m)
  },
  {
    name: "o-nas-clenovia-ligy",
    path: "/o-nas/clenovia-ligy",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/clenovia-ligy.vue").then(m => m.default || m)
  },
  {
    name: "o-nas-eticke-kodexy",
    path: "/o-nas/eticke-kodexy",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/eticke-kodexy.vue").then(m => m.default || m)
  },
  {
    name: "o-nas-kontakty",
    path: "/o-nas/kontakty",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/kontakty.vue").then(m => m.default || m)
  },
  {
    name: "o-nas-kto-sme",
    path: "/o-nas/kto-sme",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/kto-sme.vue").then(m => m.default || m)
  },
  {
    name: "o-nas-partneri-a-donori",
    path: "/o-nas/partneri-a-donori",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/partneri-a-donori.vue").then(m => m.default || m)
  },
  {
    name: "o-nas-pre-media",
    path: "/o-nas/pre-media",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/pre-media.vue").then(m => m.default || m)
  },
  {
    name: "o-nas-stanoviska-ligy",
    path: "/o-nas/stanoviska-ligy",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/stanoviska-ligy.vue").then(m => m.default || m)
  },
  {
    name: "o-nas-vyrocne-spravy",
    path: "/o-nas/vyrocne-spravy",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/vyrocne-spravy.vue").then(m => m.default || m)
  },
  {
    name: "ocenenie-dobre-kruhy",
    path: "/ocenenie-dobre-kruhy",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/ocenenie-dobre-kruhy/index.vue").then(m => m.default || m)
  },
  {
    name: "ochrana-osobnych-udajov",
    path: "/ochrana-osobnych-udajov",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/ochrana-osobnych-udajov/index.vue").then(m => m.default || m)
  },
  {
    name: "organizacia-name",
    path: "/organizacia/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/organizacia/[name].vue").then(m => m.default || m)
  },
  {
    name: "otazky-a-odpovede",
    path: "/otazky-a-odpovede",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/otazky-a-odpovede/index.vue").then(m => m.default || m)
  },
  {
    name: "peerovia-name",
    path: "/peerovia/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/peerovia/[name].vue").then(m => m.default || m)
  },
  {
    name: "peerovia",
    path: "/peerovia",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/peerovia/index.vue").then(m => m.default || m)
  },
  {
    name: "podcast-name",
    path: "/podcast/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podcast/[name].vue").then(m => m.default || m)
  },
  {
    name: "podcast",
    path: "/podcast",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podcast/index.vue").then(m => m.default || m)
  },
  {
    name: "podporte-nas",
    path: "/podporte-nas",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podporte-nas/index.vue").then(m => m.default || m)
  },
  {
    name: "podujatie-name",
    path: "/podujatie/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/[name].vue").then(m => m.default || m)
  },
  {
    name: "podujatie",
    path: "/podujatie",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/index.vue").then(m => m.default || m)
  },
  {
    name: "podujatie-slavnostny-koncert",
    path: "/podujatie/slavnostny-koncert",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/slavnostny-koncert.vue").then(m => m.default || m)
  },
  {
    name: "potrebujem-pomoc",
    path: "/potrebujem-pomoc",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/potrebujem-pomoc/index.vue").then(m => m.default || m)
  },
  {
    name: "preview",
    path: "/preview",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    name: "projekt-name",
    path: "/projekt/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/projekt/[name].vue").then(m => m.default || m)
  },
  {
    name: "projekt",
    path: "/projekt",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/projekt/index.vue").then(m => m.default || m)
  },
  {
    name: "publikacie",
    path: "/publikacie",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/publikacie/index.vue").then(m => m.default || m)
  },
  {
    name: "staznosti-a-spatna-vazba",
    path: "/staznosti-a-spatna-vazba",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/staznosti-a-spatna-vazba/index.vue").then(m => m.default || m)
  },
  {
    name: "stranka-name",
    path: "/stranka/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/stranka/[name].vue").then(m => m.default || m)
  },
  {
    name: "stranka",
    path: "/stranka",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/stranka/index.vue").then(m => m.default || m)
  },
  {
    name: "testy",
    path: "/testy",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/testy/index.vue").then(m => m.default || m)
  },
  {
    name: "video-name",
    path: "/video/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/video/[name].vue").then(m => m.default || m)
  },
  {
    name: "video",
    path: "/video",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/video/index.vue").then(m => m.default || m)
  },
  {
    name: "vsetky-vysledky-slug-categorySlug",
    path: "/vsetky-vysledky/:slug()/:categorySlug()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vsetky-vysledky/[slug]/[categorySlug].vue").then(m => m.default || m)
  },
  {
    name: "vyhladavanie-searchText",
    path: "/vyhladavanie/:searchText()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyhladavanie/[searchText].vue").then(m => m.default || m)
  },
  {
    name: "vyzva-name",
    path: "/vyzva/:name()",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzva/[name].vue").then(m => m.default || m)
  },
  {
    name: "vyzva",
    path: "/vyzva",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzva/index.vue").then(m => m.default || m)
  },
  {
    name: "vyzvy",
    path: "/vyzvy",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzvy/index.vue").then(m => m.default || m)
  },
  {
    name: "zbierka-dni-nezabudiek",
    path: "/zbierka-dni-nezabudiek",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/zbierka-dni-nezabudiek/index.vue").then(m => m.default || m)
  },
  {
    name: "vsetky-vysledky",
    path: "/vsetky-vysledky/:slug",
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vsetky-vysledky/[slug]/[categorySlug].vue").then(m => m.default || m)
  }
]